:root {
    --sidebar-width: 250px; /* Default value */
}

/* sizing classes */
.desktop {
    display: block;
}

.mobile-portrait-compact {
    display: none;
}

.mobile-landscape-compact {
    display: none;
}

.mobile-portrait-expanded {
    display: none;
}

.mobile-portrait-large {
    display: none;
}

.app-root-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.fill-remaining-space {
    flex: 1;
}

.app-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    overflow: auto;
}

.app-container-content {
    width: 100%;
}

.app-main-content {
    flex-grow: 1;
    vertical-align: top;
    /*margin-top: var(--top-menu-height); !* Same as height of top menu bar *!*/
    /*height: calc(100vh - var(--top-menu-height));*/
    /*background-color: #cfcff3;*/
    color: #000;
    overflow-y: auto;
    /*width: calc(100vh - var(--sidebar-width));*/
    padding-bottom: 50px;
}

.app-container-item {
    flex: 0 1 auto;
    padding: 2px;
    margin: 2px;
}


.app-top-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; /* Adjust to your desired height */
    background-color: var(--std-primary-green) ; /* Change this to your preferred color and level of transparency */
    backdrop-filter: blur(20px); /* This will make the content behind the menu bar blurry */
    z-index: 1000;
}

.app-top-menu-left {
    font-size: 18px;
    display: inline-block;
}

.app-top-menu-right {
    font-size: 18px;
    display: inline-block;
    float: right;
    margin-right: 10px;
}

.app-sidebar {
    position: fixed;
    left: 0;
    /*top: 60px;*/
    height: 100vh ;
    width: var(--sidebar-width); /* Adjust as needed */
    transition: width 0.15s ease-in-out;
    background-color: var(--std-secondary-blue);
    overflow-y: auto;
    /*padding-bottom: 150px;*/
    margin-bottom: 150px;
}

.app-sidebar-full {
    width: 100%;
}

.app-sidebar-closed {
    width: 0;
}

.app-content {
    flex-grow: 1; /* New property */
    margin-top: 60px;
    height: calc(100vh + 100px) ;
    transition: margin-left 0.15s ease-in-out;
}

.app-sidebar-open .app-content {
    margin-left: 240px; /* Same as the width of the sidebar */
}

.app-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style-type: none;
    cursor: pointer;
    margin: 0 0 350px;
}



.app-menu-item {
    padding: 10px;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid white;
}

.app-menu-item-selected {
    padding: 10px;
    background-color: var(--std-secondary-orange);
    color: black;
    text-decoration: none;
    border-bottom: 1px solid white;
}

.app-menu-icon {
    margin-right: 10px;
    display: inline-block;
}

.app-menu-labels {
    display: inline-block;
    vertical-align: top;
}

.app-menu-title {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--std-white);
}

.app-menu-desc {
    padding-top: 5px;
    font-size: 0.75em;
    color: var(--std-white);
    font-style: italic;
}

.app-top-user-menu {
    display: inline-block;
    width: auto;
    height: 60px;
    vertical-align: -4px;
    margin-top: 5px;
}

.app-top-menu-title {
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    vertical-align: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.app-menu-item:hover  {
    background-color: var(--std-primary-grey);
}

/* general styles */

.hide-content {
    display: none;
}

.st-main-title {
    font-size: 3em;
    font-weight: bold;
    color: var(--std-primary-grey);
    margin: 5px;
    padding: 5px;
}

.std-centered {
    text-align: center;
}


.st-lg-label {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--std-primary-grey);
    display: block;
    width: auto;
    text-align: left;
}

.std-inlined {
    display: inline-block;
    width: fit-content;
}

.std-right-pad {
    padding-right: 10px;
}

.std-full-width {
    width: 100%;
}

.std-small-top-margin {
    margin-top: 5px;
}

.std-small-bottom-margin {
    margin-bottom: 5px;
}

.std-medium-top-margin {
    margin-top: 10px;
}

.std-medium-bottom-margin {
    margin-bottom: 10px;
}

.std-large-top-margin {
    margin-top: 20px;
}

.std-large-bottom-margin {
    margin-bottom: 20px;
}

.st-inset-form {
    padding: 10px;
}


/*Login styles*/

.st-login-detail {
    width: 50%;
    display: inline-block;
    height: 100vh;
    /*background-color: #ebfdeb;*/
}

.st-login-info {
    width: 50%;
    display: inline-block;
    background-color: blue;
}

.st-login-logo {
    width: 300px;
    padding: 10px;
}

.st-login-detail input[type=text] {
    background-color: white;
    background-repeat: no-repeat;
    width: 400px;
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    font-size: 24px;
    padding: 10px;
}

.st-login-detail .st-inset-form {
    text-align: center;
}

.st-login-detail input[type=password] {
    background-color: white;
    background-repeat: no-repeat;
    width: 400px;
    border-radius: 5px;
    border: 0.5px solid lightgrey;
    padding: 10px;
    font-size: 24px;
}

.st-login-info::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/board-schematic-v2.04.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: rotate(90deg) translateY(-50%);
    /*transform-origin: 50% 0%; !* Adjust this value to change the origin of the rotation *!*/
    opacity: 0.25;
    z-index: -1;
}

.st-button-login {
    width: auto;
    min-width: 250px;

    border-radius: 5px;
    padding: 12px;
    background: var(--std-primary-green);
    font-size: xx-large;
    font-weight: bold;
    color: white;
}
.st-button-login:disabled {background-color: #f5f4f4;}

ul.pretty-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul.pretty-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-family: Arial, sans-serif;
    font-size: 18px;
}

ul.pretty-list li:last-child {
    border-bottom: none;
}

ul.pretty-list li:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}

ul.lookup-list {
    list-style-type: disc;
    padding: 0;
    margin: 0;
}

ul.lookup-list li {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    font-family: Arial, sans-serif;
    font-size: 10px;
}

ul.lookup-list li:last-child {
    border-bottom: none;
}

ul.lookup-list li:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}

.app-menu-logo {
    display: inline-block;
}

.menu-title {
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    vertical-align: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.menu-title-view {
    display: inline-block;
}

.list-item {
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    background-color: #f6f6f6;
}

.list-item-selected {
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--std-secondary-orange);
}

.list-item-editing {
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--std-primary-green);
    color: white;
}

.list-body {
    display: inline-block;
    width: 90%;
}

.list-title {
    margin: 0;
    font-size: 20px;
    color: #333;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.list-summary {
    margin: 0;
    font-size: 14px;
    color: var(--std-secondary-blue);
    display: block;
}

.list-footer {
    width: 100%;
    font-size: 8px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
}

.list-nav {
    display: inline-block;
    font-size: 20px;
    color: #333;
    float: right;
}

.list-prominent-hdr {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 8px;
    color : var(--std-primary-green);
}

.rnd-border-text {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f8ff; /* Light pastel color */
    padding: 5px;
    color: #333;
    font-size: 16px;
    width: fit-content;
    min-width: 100px;
    box-sizing: border-box;
    pointer-events: none; /* Make non-editable */
    user-select: none; /* Prevent text selection */
}